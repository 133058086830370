<template>
  <div class="login-page">
    <div class="login-pf-page">
      <div id="kc-header" class="login-pf-page-header">
        <div id="kc-header-wrapper" class="mb-5">
          <div class="kc-logo-text"><span class="mb-5">.</span></div>
        </div>
      </div>

      <div class="grid_cidadao">
        <div class="form_cidadao">
          <div class="card-desktop">
            <div
              class="cidadao_conectado mt-5 flex flex-column justify-content-center"
              style="display: none">
              <img
                class="mx-auto d-block mt-5"
                src="@/img/titulo.png"
                width="350" />
              <img
                class="mx-auto d-block mt-5"
                src="@/img/ilustracao.png"
                width="420" />
            </div>
          </div>
          <br class="card-mobile" />
          <br class="card-mobile" />
          <div
            class="card-pf mt-5 mb-5 flex flex-column justify-content-evenly align-items-center">
            <div class="mx-auto d-block pt-5">
              <a href="https://paraiba.pb.gov.br/" role="link" target="_Blank">
                <img
                  class="card-desktop"
                  src="@/img/logo-gov-pb.png"
                  alt="Governo do Estado da Paraíba"
                  width="270" />
                <img
                  class="card-mobile"
                  src="@/img/logo-gov-pb.png"
                  alt="Governo do Estado da Paraíba"
                  width="150" />
              </a>
            </div>
            <div class="col-11 md:col-8">
              <form novalidate @submit.prevent="login()">
                <label
                  v-if="false"
                  for="username"
                  class="block text-900 font-medium"
                  >CPF</label
                >
                <InputMask
                  v-if="false"
                  id="username"
                  v-model="v$.username.$model"
                  mask="999.999.999-99"
                  type="text"
                  class="w-full"
                  :class="{ 'p-invalid': v$.username.$invalid && submitted }"
                  required />
                <small v-if="v$.username.$invalid && submitted" class="p-error"
                  >Usuário é obrigatório</small
                >

                <label
                  v-if="false"
                  for="password"
                  class="mt-3 block text-900 font-medium"
                  >Senha</label
                >
                <InputText
                  v-if="false"
                  id="password"
                  v-model="v$.password.$model"
                  type="password"
                  class="w-full"
                  :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                  required
                  name="password" />
                <small v-if="v$.password.$invalid && submitted" class="p-error"
                  >Senha é obrigatória</small
                >
                <div v-if="false" class="col-12">
                  <a
                    class="mb-2 text-right float-right font-medium no-underline text-blue-500 cursor-pointer"
                    target="_blank"
                    :href="resetLink"
                    >Recuperar Senha
                  </a>
                </div>
                <Button class="w-full mb-2" type="submit" :disabled="loading">
                  <span v-if="loading" class="pi pi-spin pi-spinner"></span>
                  <span class="p-button-label">Acessar</span>
                </Button>

                <div class="mt-2 text-center float-center">
                  Caso não tenha e-mail cadastrado, clique em
                  <strong
                    ><a
                      class="font-medium no-underline text-blue-500 text-right cursor-pointer"
                      target="_blank"
                      :href="recuperarEmail"
                      >PRIMEIRO ACESSO
                    </a></strong
                  >
                </div>
                <Button
                  class="p-button p-button-secondary w-full mt-3"
                  size="small"
                  @click="irParaVersaoAntiga()">
                  <span class="p-button-label">Acessar versão antiga</span>
                </Button>
              </form>
            </div>

            <div class="logo_codata card-desktop">
              <a href="https://codata.pb.gov.br/" role="link" target="_blank">
                <img src="@/img/logo-codata.png" width="130" alt="Codata" />
              </a>
            </div>
            <div class="logo_codata card-mobile mb-5">
              <a href="https://codata.pb.gov.br/" role="link" target="_blank">
                <img src="@/img/logo-codata.png" width="130" alt="Codata" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Config from '@/config'
import auth from '@/plugins/auth'

export default {
  name: 'LoginIndex',

  props: {
    titulo: {
      type: String,
      default: '',
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      http_login: null,
      loading: false,
    }
  },

  computed: {
    recuperarEmail: () => {
      return `${Config.PRIMEIRO_ACESSO_URL}`
    },

    resetLink: () => {
      return `${Config.SSO_URL}/${Config.REALM_PB}/protocol/openid-connect/auth?client_id=portal_servidor_reset&redirect_uri=https%3A%2F%2Fportaldoservidor.pb.gov.br%2Flogin%2F&response_type=code&scope=openid`
    },
  },

  created() {
    if (this.$keycloak.authenticated && this.$auth.refresh_token == null) {
      this.$auth.updateToken(
        window.location.origin + window.location.pathname,
        this.submit,
      )
    }
  },

  validations() {
    return {
      username: { required },
      password: { required },
    }
  },

  methods: {
    validate(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Usuário ou senha inválido(s).',
          life: 10000,
        })
        return
      }
      this.submit()
    },

    login() {
      this.$auth.updateToken(
        window.location.origin + window.location.pathname,
        this.submit,
      )
    },

    irParaVersaoAntiga() {
      location.href = 'https://sead.secadm.pb.gov.br/portaldoservidor/'
    },

    async parseJwt(token) {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join(''),
      )
      return JSON.parse(jsonPayload)
    },

    async submit() {
      this.loading = true

      try {
        const { data } = {
          data: {
            access_token: auth.token,
            refresh_token: auth.refresh_token,
            expires_in: auth.expires_in,
          },
        }
        var jsonparsed = await this.parseJwt(data.access_token)
        if (!jsonparsed.resource_access.pbconsig) {
          throw {
            response: {
              data: { error_description: 'Usuário sem permissões.' },
            },
          }
        }
        this.success(
          data,
          jsonparsed.resource_access.pbconsig.roles,
          jsonparsed,
        )
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$toast.add({
          severity: 'error',
          summary: this.getErrorMessage(error),
          life: 10000,
        })
      }
    },

    async success(data, roles, jsonparsed) {
      const usuario = {
        email: jsonparsed.email,
        primeironome: jsonparsed.given_name,
        sobrenome: jsonparsed.family_name,
        nomecompleto: jsonparsed.name,
        usuario: jsonparsed.preferred_username,
        uuid: jsonparsed.sub,
      }

      await this.$auth.login({
        user: usuario,
        token: data.access_token,
        refresh_token: data.refresh_token,
        roles: roles,
        expires_in: data.expires_in,
      })

      this.$router.push('/').catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Acesso não autorizado a este módulo.',
          life: 10000,
        })
      })
    },

    getErrorMessage(error) {
      if (error.response) {
        switch (error.response.data.error_description) {
          case 'Account is not fully set up':
            return 'Usuário com restrição. Entre em contato com a gestão do PBConsig.'
          case 'Invalid user credentials':
            return 'Usuário ou senha inválido(s)'
          case 'Account disabled':
            return 'Conta desativada.'
          default:
            return error.response.data.error_description
        }
      }
      return 'Erro ao realizar login. Verifique sua conexão.'
    },
  },
}
</script>

<style>
.login-page {
  font-family: 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif;

  background-size: cover;
  background-repeat: repeat;
  background-color: #1c84f7 !important;
  height: 100vh;
}

.logos {
  max-width: 500px;
  height: 50px;
  display: flex;
}

.logo_codata {
  margin: 10px auto 0;
}

.logo_codata a img {
  max-width: 15rem;
  display: flex;
}

.logo_governo {
  margin: auto;
}

.card-pf {
  padding-top: 0 !important;
  border-radius: 0 16px 16px 0;
  max-width: 550px !important;
  width: 560px !important;
  height: 580px;
  background-color: white;
}

#kc-social-providers {
  margin-top: 25px;
}

#kc-social-providers ul {
  align-items: center;
  display: grid !important;
}

.login-pf-signup {
  margin-top: 5px !important;
}

@media (min-width: 768px) and (max-width: 900px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 410px) and (max-width: 767px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 0px !important;
    height: 10px !important;
  }
}

@media (max-width: 410px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 0px !important;
    height: 5px !important;
  }
}

.info-servicos {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  color: var(--blue-color) !important;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
}

.info-clique-botao,
.info-govbr {
  text-align: center;
  font-size: 14px;
  color: #777;
  line-height: 24px;
  font-weight: 500;
}

.govBr {
  color: #1351b4;
  font-weight: 700;
}

.separador {
  height: 1px;
  background: #d0948a;
  margin-bottom: 10px !important;
}

#kc-info-wrapper p {
  font-size: 12px;
  font-weight: 500;
  color: #777;
  margin: 0 0 8px;
}

#kc-info-wrapper p a {
  margin-left: 0px !important;
}

.login-pf-page,
#kc-info {
  padding-bottom: 20px;
}

#kc-content {
  height: 400px;
}

.grid_cidadao {
  margin: 0 auto;
  width: 980px;
  display: block;
}

.form_cidadao {
  display: grid;
  grid-template-columns: 420px 560px;
}

.cidadao_conectado {
  background-color: #145da1;
  /* background-image: url("./ilustracao.png") !important; */

  height: 580px;
  width: 420px;
  border-radius: 16px 0 0 16px;
}

:root {
  --shadow: 0 0px 3px -2px rgba(0, 0, 0, 0.2), 0 0px 4px 0 rgba(0, 0, 0, 0.14),
    0 0px 8px 0 rgba(0, 0, 0, 0.12);
}

.cidadao_conectado,
.card-pf {
  box-shadow: var(--shadow);
}

@media (max-width: 840px) {
  .login-pf-page .card-pf {
    margin-left: 0px !important;
  }
}

@media (max-width: 1000px) {
  .cidadao_conectado {
    display: none;
  }

  .card-pf {
    border-radius: 16px 16px 16px 16px;
  }
}

@media (min-width: 700px) and (max-width: 1000px) {
  .grid_cidadao {
    width: 560px;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .grid_cidadao {
    width: 470px;
  }

  .form_cidadao {
    display: block;
  }

  .card-pf {
    max-width: 470px !important;
    width: 100%;
  }

  #kc-content {
    height: 420px;
  }
}

@media (max-width: 499px) {
  .grid_cidadao {
    width: 350px;
  }

  .form_cidadao {
    display: block;
  }

  .card-pf {
    max-width: 350px !important;
  }

  #kc-content {
    height: 500px;
  }
}

@media (max-height: 810px) {
  #kc-header {
    display: none;
  }
}
</style>
